$font-path: "/common/fonts";

$font-myriadPro: "MyriadPro";
$myriadPro-regular: "/MYRIADPRO-REGULAR.OTF";
$myriadPro-bold: "/MYRIADPRO-BOLD.OTF";
$myriadPro-light: "/MyriadPro-Light.otf";
$myriadPro-semibold: "/MYRIADPRO-SEMIBOLD.OTF";

$font-open-sans: "Open_Sans";
$open-sans-regular: "/OpenSans-Regular.ttf";
$open-sans-semibold: "/OpenSans-SemiBold.ttf";

@font-face {
  src: url($font-path + "/" + $font-myriadPro + $myriadPro-bold)
    format("opentype");
  font-family: $font-myriadPro;
  font-weight: 700;
  font-style: normal;
  font-size: 32px;
}

@font-face {
  src: url($font-path + "/" + $font-myriadPro + $myriadPro-semibold)
    format("opentype");
  font-family: $font-myriadPro;
  font-weight: 600;
  font-style: normal;
  font-size: 24px;
}

@font-face {
  src: url($font-path + "/" + $font-myriadPro + $myriadPro-regular)
    format("opentype");
  font-family: $font-myriadPro;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
}

@font-face {
  src: url($font-path + "/" + $font-myriadPro + $myriadPro-light)
    format("opentype");
  font-family: $font-myriadPro;
  font-weight: 300;
  font-style: normal;
  font-size: 12px;
}

@font-face {
  src: url($font-path + "/" + $font-open-sans + $open-sans-regular)
    format("truetype");
  font-family: $font-open-sans;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
}

@font-face {
  src: url($font-path + "/" + $font-open-sans + $open-sans-regular)
    format("truetype");
  font-family: $font-open-sans;
  font-weight: 300;
  font-style: normal;
  font-size: 12px;
}

@font-face {
  src: url($font-path + "/" + $font-open-sans + $open-sans-semibold)
    format("truetype");
  font-family: $font-open-sans;
  font-weight: 600;
  font-style: normal;
  font-size: 16px;
}

.prevent-text-highlight {
  user-select: none;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.sidenav-text {
  font-family: "Open_Sans", serif;
  font-weight: 600;
  font-style: normal;
  font-size: 14px;
  line-height: 21px;
}

.tag {
  font-family: "Open_Sans", serif;
  font-weight: 600;
  font-style: normal;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.15px;
}

.disabled {
  font-family: "Open_Sans", serif;
  font-weight: 300;
  font-style: normal;
  font-size: 12px;
  line-height: 18px;
}

.limit-rows-to-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.limit-rows-to-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.limit-rows-to-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.fit-content {
  width: 100%;

  img {
    max-width: 100%;
    object-fit: contain;
  }

  * {
    max-width: 100%;
  }
}

.full-width {
  width: 100%;
}

.word-wrap {
  word-wrap: break-word;
}

.layout-container {
  padding-left: 160px;
  padding-right: 160px;
}

.shadow {
  box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.04);
}
